var cookieNamePdf = 'pdf_pages';
var cookiePdf;
var context       = '[context]';
var pdfUrl        = '/pdfondemand/printpdf?docId=';

$(function(){
	
  pdfUrl += $(context).attr('data-documentid');

  if ($.cookie(cookieNamePdf) == null) cookiePdf = '';
  else cookiePdf = $.cookie(cookieNamePdf);
    
  $('#add-to-pdf').click(function(){
	  var nodeInfo = $(this).data('nodeinfo')
	  if (cookiePdf.indexOf(nodeInfo) == -1) {
		  editCookie(nodeInfo,'add');
		  $('#my-report').css('font-weight','bold');
		  $(this)
		  .data('original-title','this page is already added to my report')
		  .css('opacity','0.7')
		  ;
	  }
  }).each(function(){
	  var nodeInfo = $(this).data('nodeinfo');
	  if (cookiePdf.indexOf(nodeInfo) != -1) $(this).data('original-title','this page has already been added to my report');
  });
  
  if ($(context).length!=0) {
  
    //initializing jquery ui accordion
   	//using custom icons for jquery UI accordion
   	var icons = {
   		header: "cUiArrowE",
   		activeHeader: "cUiArrowS"
   	};
   
   	//init and close all chpaters by default except for the aboveone which is open by default.
   	$(".cDivAnnualReportCreatorAccordion").accordion({
   		heightStyle: "content",
   		icons: icons,
   		collapsible : true,
   		active: false
   	});
   
   	//fix for stopping event propagation to enable checkbox clicking inside a jquery ui accordion
   	$('input,label',context).click(function(e) {
   		e.stopPropagation();
   	});
   
    //check or uncheck child sections, check or uncheck parent section depending on self and siblings
    $('h3 input',context).click(function(){
      if ($(this).prop('checked')) {
        $(this).closest('div').find('input').prop('checked',true);
        $(this).parents('.ui-accordion-content').each(function(){
          if ($(this).find('input:not(:checked)').length==0) $(this).parent().find('h3 input').prop('checked',true);
        });
      }
      else {
        $(this).parents('div').find('> h3 > input').prop('checked',false);
        $(this).closest('div').find('input').prop('checked',false);
      }
   		prepareReport();
    });
    
    //check or uncheck parent section depending on self and siblings
    $('li input',context).click(function(){
      if ($(this).prop('checked')) {
        $(this).parents('.ui-accordion-content').each(function(){
          if ($(this).find('input:not(:checked)').length==0) $(this).parent().find('h3 input').prop('checked',true);
        });
      }
      else {
        $(this).parents('div').find('> h3 > input').prop('checked',false);
      }
   		prepareReport();
    });
    
   	//init 'download my report' dialog
   	$('.cDivAnnualReportCreatorPopup').dialog({
   		autoOpen: false,
   		modal: true,
   		width:400,
   		minHeight:300,
   		resizable: false,
   		dialogClass: 'ui-widget-shadow-custom'
   	});
   
   	//dialog 'close' button action
   	$('.cButtonClose').click(function(){
   		$(this).parent().dialog("close");
   	});
   
   	//open pdf options dialog
   	$('#iDivAnnualReportCreatorButton').click(function(){
   		$("#iDivAnnualReportDownloadOptionsPopup").dialog("open");
   	});
  
    $('#iButtonDownloadOptionsMyReport').click(function(){
      download_pdf_action();
    });
   	
   	updatePageList();
  }
  
});

function splitNodeInfo(value) {
  var array = value.split('|');
  return {id: array[0], title: array[1]};
}

function editCookie(nodeInfo,action) {
  if (action=='add') cookiePdf += nodeInfo + ';';
  if (action=='rem') cookiePdf = cookiePdf.replace(nodeInfo + ';','');
  $.cookie(cookieNamePdf,cookiePdf,{expires:7,path:'/'});
}

function updatePageList() {
  var nodes = cookiePdf.split(';');
  for(var i=0; i<nodes.length-1; i++) {
    var nodeInfo = splitNodeInfo(nodes[i]);
	  $('#iInputT'+nodeInfo.id+'').prop('checked',true);
  }
  // open sections with checked descendants
  $('.cDivAnnualReportCreatorAccordion:has(input:checked) > h3:not(:has(input:checked))').click();
}

function download_pdf_action() {
  var url = pdfUrl;
  if ($('#iInputEco').prop('checked')) url += '&variant=printer-friendly';
  url += '&nodes=';
  
  var nodes = cookiePdf.split(';');
  if (nodes.length>0) {
  	for(var i=0; i<nodes.length-1; i++) {
  		var nodeInfo = splitNodeInfo(nodes[i]);
  		url += nodeInfo.id + '-';
  	}
    url = url.substring(0,url.length-1);
  } else {
    alert('No pages selected.');
    return;
  }
  
  location.href = url;
  //$.cookie(cookieNamePdf,'',{expires:7,path:'/'});
}

function prepareReport(){
	$('input:checked',context).each(function(){
		var value = $(this).data('nodeinfo');
		if (cookiePdf.indexOf(value) == -1) editCookie(value,'add');
	});
	$('input:not(:checked)',context).each(function(){
		var value = $(this).data('nodeinfo');
		editCookie(value,'rem');
	});
}